import React from 'react';
import PropTypes from 'prop-types';
import './buttonWhatsapp.scss';

const WhatsAppIcon = ({ onClick }) => (
  <div className="whatsapp-icon-container" onClick={onClick}>
    <span className="whatsapp-icon" />
    <div className="right-image-tooltip_wa">
      <div className="comment-connector_wa" />
      <div className="contact_wa">
        <span className="title-contact_wa">Habla con un experto comercial</span>
      </div>
    </div>
  </div>
);

WhatsAppIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default WhatsAppIcon;
