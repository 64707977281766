/* eslint-disable no-else-return */
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { getShippingsWithNoveltiesService } from '../../services/novelties.service';

import { operationValidate } from '../../utils/role';
import { fullWidth, whiteLogoRegister } from '../../utils/generals';
import { showDependingOnBusinessFlag } from '../../utils/businessFlag';
import { redirectToOtherPlatforms } from '../../utils/intercommunication';

import dataSections from '../../constants/dataSections';

import ButtonInternational from '../buttonInternational';
import WhatsAppIcon from '../buttonWhatsapp';
import KustomerChat from '../buttonChatKustomer';
import LeftSideMenuCards from './leftSideMenuCards';

import './leftSideMenu.scss';

const LeftSideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.auth.user);
  const businessFlags = useSelector((state) => state.globalData.businessFlags);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const [currentSection, setCurrentSection] = useState('');
  const [numberOfNovelties, setNumberOfNovelties] = useState(0);
  const [noveltiesCenterVisibility, setNoveltiesCenterVisibility] = useState(false);
  // const [tusRecaudosVisibility, setTusRecaudosVisibility] = useState(false);

  useEffect(() => {
    const getShippingWithNoveltiesFunction = async () => {
      const responseShippingWithNovelties = await getShippingsWithNoveltiesService({
        userId: user._id, state: 'Sin gestión', page: 1,
      });
      setNumberOfNovelties(responseShippingWithNovelties.totalDocuments);
    };

    if (user && !user.role) getShippingWithNoveltiesFunction();
  }, [user]);

  useEffect(() => {
    if (user && businessFlags) {
      const temporalNoveltiesCenterVisibility = showDependingOnBusinessFlag({
        businessFlags, section: 'noveltiesCenter', feature: 'see', userEmail: user.email,
      });

      setNoveltiesCenterVisibility(temporalNoveltiesCenterVisibility);
    }
  }, [user, businessFlags]);

  useEffect(() => {
    setCurrentSection(location.pathname);
  }, [location.pathname]);

  const dataToMap = () => {
    if (user && user.role) return dataSections.internalAutenticated;
    if (user && !user.role) return dataSections.userAutenticated;
    return dataSections.notAutenticated;
  };

  const redirectModule = (option) => {
    if (option.redirect) {
      redirectToOtherPlatforms({ urlToRedirect: option.url });
    } else if (option.blank) {
      window.open(option.url, '_blank');
    } else {
      navigate(option.url);
    }
  };

  return (
    <>
      <div
        id={operationValidate(user) ? 'left-side-menu-hover' : 'left-side-menu'}
        className={(fullWidth(currentSection) !== whiteLogoRegister(currentSection)) ? 'hidden' : ''}
      >
        {dataToMap().map((option) => (
          (option.label === 'Novedades' && noveltiesCenterVisibility)
          || (option.label !== 'Tus recaudos' && option.label !== 'Novedades' && !option.noLeftMenu)
          || (option.label === 'Tus recaudos')
        ) && (
        <LeftSideMenuCards
          key={`option-left-side-menu-${option.label}`}
          img={option.Icon && option.IconDark && (
            currentSection === option.url ? option.Icon : option.IconDark
          )}
          active={currentSection === option.url}
          shadding={option.shadding}
          label={option.label}
          elementRight={(option.label === 'Novedades' && numberOfNovelties > 0) ? (
            <span className="count-unread">{numberOfNovelties}</span>
          ) : null}
          onClick={() => redirectModule(option)}
          itemsHover={operationValidate(user)}
        />
        ))}
      </div>
      {!isMobileDevice && (
        <div className="container-help-contacts">
          <div className="container-help-international">
            <ButtonInternational />
            {user ? (
              <div className="container-chatKustomer-icon">
                <KustomerChat user={user} />
              </div>
            ) : (
              <div className="container-whatsapp-icon">
                <WhatsAppIcon
                  onClick={() => window.open('https://api.whatsapp.com/send?phone=5714924696&text=Hola%3A%20quiero%20m%C3%A1s%20informaci%C3%B3n%20para%20hacer%20mis%20env%C3%ADos', '_blank', 'noopener')}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LeftSideMenu;
