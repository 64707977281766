/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './buttonInternational.scss';

import helpIcon from '../../assets/images/Icon ion-help-outline_v2.svg';
import arrowtop from '../../assets/images/arrow-top.svg';
import helpSmall from '../../assets/images/help-small.svg';

import { useValidationCountry } from '../../utils/validationCountry';
import { helpCenterUrl, integrateStoreUrl } from '../../constants/urls';

/**
 * Componente que abarca los botones de la app
 * @param {string} text - texto a mostrarse en el botón
 * @param {string} id - propiedad única del componente
 */

const ButtonInternational = ({
  id,
}) => {
  const [isRightImageVisible, setIsRightImageVisible] = useState(false);
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsRightImageVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const isCountryValid = useValidationCountry('170');

  const handleImageClick = () => {
    setIsRightImageVisible(!isRightImageVisible);
  };

  const handleVisibleTooltip = () => {
    setIsVisibleTooltip(!isVisibleTooltip);
  };

  const handleMouseLeave = () => {
    setIsVisibleTooltip(false);
  };

  const handleOpenLink = (url) => {
    window.open(url, 'WindowName', 'noopener');
  };

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' || event.key === ' ') {
      callback();
    }
  };

  return (
    <div className="container-button-international" ref={containerRef}>
      <div className="flexbox-container-international">
        <div
          className="z-index-international"
          onClick={handleImageClick}
          onKeyUp={handleImageClick}
          role="button"
          aria-hidden
        >
          <button
            id={id}
            type="button"
            className="button-international"
          >
            <img
              src={helpIcon}
              alt={helpIcon}
              onMouseOver={handleVisibleTooltip}
              onMouseOut={handleMouseLeave}
              onFocus={handleVisibleTooltip}
              onBlur={handleMouseLeave}
            />
          </button>
        </div>
        {isRightImageVisible && (
          <div className={`right-image-international ${isCountryValid ? 'height-auto' : ''}`}>
            <div className="hover-container-set">
              <div
                className="container-set"
                onClick={() => handleOpenLink(helpCenterUrl)}
                onKeyDown={(e) => handleKeyDown(e, () => handleOpenLink(helpCenterUrl))}
                role="button"
                tabIndex={0}
              >
                <div className="image-container left">
                  <img src={helpSmall} alt={helpSmall} />
                </div>
                <div className="image-container left">
                  <span className="title-contact-international">Ir al Centro de Ayuda</span>
                </div>
                <div className="image-container right">
                  <img src={arrowtop} alt={arrowtop} />
                </div>
              </div>
            </div>
            <hr className={isCountryValid ? 'hidden-element' : ''} />
            <div className={`hover-container-set ${isCountryValid ? 'hidden-element' : ''}`}>
              <div
                className="container-set"
                onClick={() => handleOpenLink(integrateStoreUrl)}
                onKeyDown={(e) => handleKeyDown(e, () => handleOpenLink(integrateStoreUrl))}
                role="button"
                tabIndex={0}
              >
                <div className="image-container left">
                  <span className="title-contact-international-under">
                    Integra tu tienda online
                  </span>
                </div>
                <div className="image-container right">
                  <img src={arrowtop} alt={arrowtop} />
                </div>
              </div>
            </div>
          </div>
        )}
        {isVisibleTooltip && !isRightImageVisible && (
          <div className="right-image-tooltip">
            <div className="comment-connector" />
            <div className="contact">
              <span className="title-contact">Centro de Ayuda</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ButtonInternational.propTypes = {
  id: PropTypes.string,
};

ButtonInternational.defaultProps = {
  id: '',
};

export default ButtonInternational;
