import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadKustomerChat } from 'utils/loadChatKustomer';

const KustomerChat = () => {
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user) {
      console.log('Cargando KustomerChat con el usuario:', user);
      loadKustomerChat(user, null, false);
    } else {
      console.log('El usuario no está completamente cargado aún.');
    }
  }, [user]);

  return <div id="kustomer-chat" />;
};

export default KustomerChat;
